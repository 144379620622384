@import 'src/design-system/styles/typography.mixins';
@import '../../../../styles/animation-variables';

.filter {
  display: flex;
  flex-direction: column;
  padding-top: 28px;
  padding-bottom: 36px;
}

.title {
  @include font-style-h3;
  color: var(--text-10);
  margin: 0 0 30px;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.sportsList {
  margin-bottom: 18px;
}

// used in sorts and datePick
.block {
  @include font-style-tiny;
  display: flex;
  justify-content: center;
  align-items: center;

  height: 41px;

  background: var(--shape-10);
  border-radius: 4px;

  transition: $defaultTransitionDuration $smoothTransitionTiming background-color;

  &:hover {
    background: var(--shape-50);
  }

  &:focus {
    outline: none;
    background: var(--shape-50);
  }
}
