@import '../Filter.module';

.sports {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  width: 100%;
}

.others {
  position: relative;
}
