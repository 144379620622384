@import 'src/design-system/styles/typography.mixins';
@import '../../../../../styles/animation-variables';

.button {
  @include font-style-tiny;
  display: flex;
  justify-content: center;
  align-items: center;

  height: 41px;
  padding: 10px 16px;
  box-sizing: border-box;
  border-radius: 4px;
  border: 1px solid var(--text-40);

  color: var(--text-10);
  background: transparent;

  white-space: nowrap;
  transition: $defaultTransitionDuration $smoothTransitionTiming background-color;
  cursor: pointer;

  &:hover {
    background: var(--text-40);
  }

  &:focus {
    outline: none;
  }
}

.selected {
  background: var(--button-primary-default);
  border: none;

  &:hover,
  &:focus {
    background-color: var(--button-primary-hover);
  }
}
